<template>
  <div class="activity">
    <div class="header_title_agent">
      <p>活跃用户</p>
    </div>

    <div class="real_con">
      <div class="echarts_real">
        <div class="echarts_real_date">
          <el-select v-model="optionValue">
            <el-option
              v-for="item in poolOption"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

        <div style="height: 350px">
          <div id="main"></div>
        </div>
      </div>

      <div class="table_con_agent">
        <el-date-picker
          v-model="value2"
          align="right"
          type="date"
          :picker-options="pickerOptions"
          :clearable="false"
          prefix-icon="el-icon-arrow-down"
        >
        </el-date-picker>

        <el-table :data="tableData" border style="width: 96%">
          <el-table-column prop="date" label="手机号"> </el-table-column>
          <el-table-column prop="name" label="注册日期"> </el-table-column>
          <el-table-column prop="address" label="最近活跃时间">
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
export default {
  data() {
    return {
      latelyDate: "", // 选择时间展示echarts
      poolOption: [
        {
          value: "1",
          label: "最近1个月",
        },
        {
          value: "2",
          label: "最近3个月",
        },
        {
          value: "3",
          label: "最近6个月",
        },
        {
          value: "4",
          label: "最近12个月",
        },
      ],
      optionValue: "4",

      option: {
        tooltip: {
          trigger: "axis",
          // 取消鼠标移入折线图时的虚线指示器
          axisPointer: {
            type: "none",
          },
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
        },
        yAxis: {
          type: "value",
          boundaryGap: [0, "30%"],
        },
        visualMap: {
          type: "piecewise",
          show: false,
          dimension: 0,
          seriesIndex: 0,
          pieces: [
            {
              gt: 1,
              lt: 3,
              color: "rgba(28, 196, 111, 0.4)",
            },
            {
              gt: 5,
              lt: 7,
              color: "rgba(28, 196, 111, 0.4)",
            },
          ],
        },
        series: [
          {
            type: "line",
            smooth: 0.6,
            symbol: "none",
            lineStyle: {
              color: "#00BA5D",
              width: 5,
            },

            areaStyle: {
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(28, 196, 111, 0.78)",
                },
                {
                  offset: 1,
                  color: "rgba(28, 196, 111, 0)",
                },
              ]),
            },
            data: [
              ["1月", 200],
              ["2月", 560],
              ["3月", 750],
              ["4月", 580],
              ["5月", 250],
              ["6月", 300],
              ["7月", 450],
              ["8月", 300],
              ["9月", 100],
              ["10月", 340],
              ["11月", 210],
              ["12月", 290],
            ],
          },
        ],
      },

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周前",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      value2: new Date(),

      tableData: [
        {
          date: "13930475305",
          name: "2022-12-26 20:52:51",
          address: "2022-12-29",
        },
        {
          date: "18335394913",
          name: "2022-12-24 10:27:54",
          address: "2022-12-29",
        },
        {
          date: "18603696319",
          name: "2022-12-23 13:41:57",
          address: "2022-12-29",
        },
        {
          date: "19883293943",
          name: "2022-12-22 09:59:09",
          address: "2022-12-29",
        },
        {
          date: "13429086061",
          name: "2022-12-22 05:41:22",
          address: "2022-12-29",
        },
        {
          date: "13513634324",
          name: "2022-12-21 21:25:42",
          address: "2022-12-29",
        },
        {
          date: "15201984783",
          name: "2022-12-20 20:18:18",
          address: "2022-12-29",
        },
        {
          date: "13930475305",
          name: "2022-12-26 20:52:51",
          address: "2022-12-29",
        },
        {
          date: "18335394913",
          name: "2022-12-24 10:27:54",
          address: "2022-12-29",
        },
        {
          date: "18603696319",
          name: "2022-12-23 13:41:57",
          address: "2022-12-29",
        },
        {
          date: "19883293943",
          name: "2022-12-22 09:59:09",
          address: "2022-12-29",
        },
        {
          date: "13429086061",
          name: "2022-12-22 05:41:22",
          address: "2022-12-29",
        },
        {
          date: "13513634324",
          name: "2022-12-21 21:25:42",
          address: "2022-12-29",
        },
        {
          date: "15201984783",
          name: "2022-12-20 20:18:18",
          address: "2022-12-29",
        },
      ],
    };
  },

  mounted() {
    var chartDom = document.getElementById("main");
    var myChart = echarts.init(chartDom);
    myChart.setOption(this.option);
  },

  methods: {
    handleCommand(command) {
      console.log(command);
      this.latelyDate = command;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-weight: 400;
}
@media screen and (max-width: 1226px) {
  .real_con {
    flex-direction: column;

    .echarts_real {
      width: 100% !important;
      z-index: 100;
    }

    .table_con_agent {
      width: 100% !important;
      margin-left: 0 !important;
      margin-top: 20px;

      .el-table {
        overflow-y: scroll;
      }
    }
  }
}
.activity {
  width: 100%;

  .real_con {
    display: flex;
    height: calc(100% - 100px);

    ::v-deep .el-select {
      width: 123px;
      .el-input__inner {
        border: none;
        color: #202226;
        background-color: unset;
      }
      .el-input__suffix-inner {
        width: 20px;
        height: 16px;
        background: #f2f5fa;
        border-radius: 4px;
      }
    }
    .echarts_real {
      width: 45%;
      height: 400px;
      background: #fff;
      border-radius: 24px;

      .echarts_real_date {
        display: flex;
        align-items: center;
        padding-top: 12px;
      }

      #main {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
      }
    }

    ::v-deep .el-date-editor {
      width: 150px;

      .el-input__inner {
        border: none;
        color: #202226;
        background-color: unset;
        padding-left: 15px;
        margin: 13px 0;
      }

      .el-input__prefix,
      .el-input__suffix {
        right: 5px !important;
        margin-left: 83px;
        line-height: 64px;
      }
      .el-input__icon {
        width: 25px;
        height: 20px;
        background: #f2f5fa;
        border-radius: 4px;
        line-height: 20px;
      }
    }

    .table_con_agent {
      width: 53%;
      margin-left: 25px;
      background: #fff;
      z-index: 1;
      border-radius: 24px;
      padding-bottom: 20px;

      .el-table {
        border-radius: 8px;
        margin: 0 auto;
        height: calc(100% - 60px);
        overflow-y: scroll;
      }
    }
  }
}
.el-select-dropdown {
  .el-select-dropdown__item.selected {
    color: #202226;
  }

  .el-select-dropdown__item.hover {
    background: #f2f5fa;
    border-radius: 8px;
    width: 92%;
    margin: 0 auto;
  }
  .el-select-dropdown__item {
    padding: 0 10px;
    width: 92%;
    margin: 0 auto;
  }

  .el-select .el-input .el-select__caret {
    color: rgba(32, 34, 38, 1) !important;
  }
}
</style>
